@use '@angular/material' as mat;
@import '_vars';

/* For use in src/lib/core/theming/_palette.scss */
$md-pale-night: (
    50 : #e5e6e8,
    100 : #bfc0c5,
    200 : #94969f,
    300 : #696c78,
    400 : #494d5b,
    500 : #292d3e,
    600 : #242838,
    700 : #1f2230,
    800 : #191c28,
    900 : #0f111b,
    A100 : #5e70ff,
    A200 : #2b43ff,
    A400 : #001bf7,
    A700 : #0019de,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-pale-night-foreground: (
    50 : #edeef2,
    100 : #d1d4df,
    200 : #b3b7ca,
    300 : #959ab5,
    400 : #7e84a5,
    500 : #676e95,
    600 : #5f668d,
    700 : #545b82,
    800 : #4a5178,
    900 : #393f67,
    A100 : #b9c2ff,
    A200 : #8696ff,
    A400 : #536aff,
    A700 : #3a54ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$mat-pale-night-theme-background: (
  status-bar: black,
  app-bar:    map_get($md-pale-night, 700),
  background: map_get($md-pale-night, 700),
  hover:      rgba(#9f7f8e, 0.04), // TODO(kara): check style with Material Design UX
  card:       map_get($md-pale-night, 600),
  dialog:     map_get($md-pale-night, 600),
  disabled-button: rgba(#9f7f8e, 0.12),
  raised-button: map-get($md-pale-night, 600),
  focused-button: $light-focused,
  selected-button: map_get($md-pale-night, 700),
  selected-disabled-button: map_get($md-pale-night, 600),
  disabled-button-toggle: black,
  unselected-chip: map_get($md-pale-night, 500),
  disabled-list-option: black,
  tooltip: map_get(mat.$orange-palette, 700),
);

$mat-pale-night-theme-foreground: (
  base:              map-get($md-pale-night-foreground, 400),
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba(white, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-secondary-text,
  icon:              map-get($md-pale-night-foreground, 200),
  icons:             map-get($md-pale-night-foreground, 200),
  text:              map-get($md-pale-night-foreground, 200),
  slider-min:        map-get($md-pale-night-foreground, 200),
  slider-off:        rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);

$maw-pale-night-primary: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$maw-pale-night-accent:  mat.define-palette(mat.$orange-palette, A200, A100, A400);
$maw-pale-night-warn:    mat.define-palette(mat.$red-palette);

$maw-pale-night-theme: (
    color: (
        primary: $maw-pale-night-primary,
        accent: $maw-pale-night-accent,
        warn: $maw-pale-night-warn,
        is-dark: true,
        foreground: $mat-pale-night-theme-foreground,
        background: $mat-pale-night-theme-background
    ),
    primary: $maw-pale-night-primary,
    accent: $maw-pale-night-accent,
    warn: $maw-pale-night-warn,
    is-dark: true,
    foreground: $mat-pale-night-theme-foreground,
    background: $mat-pale-night-theme-background,
    typography: mat.define-typography-config(),
    density: -1
);

.maw-pale-night-theme {
    mat-card {
        color: map-get($maw-pale-night-accent, 50);
    }
}
