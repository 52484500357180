@use '@angular/material' as mat;

@mixin app-upload-component-theme($theme) {
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .upload-button {
        color: mat.get-color-from-palette($accent) !important;
    }

    .cancel-button,
    .delete-button {
        color: mat.get-color-from-palette($warn) !important;
    }
}
