@use '@angular/material' as mat;
@import '_vars';

/* For use in src/lib/core/theming/_palette.scss */
/* https://www.uplabs.com/posts/android-fluent-design */
$md-mulled-wine: (
    50 : #eae9eb,
    100 : #c9c8cc,
    200 : #a6a3aa,
    300 : #827e88,
    400 : #67626f,
    500 : #4c4655,
    600 : #453f4e,
    700 : #3c3744,
    800 : #332f3b,
    900 : #24202a,
    A100 : #a374ff,
    A200 : #8141ff,
    A400 : #5f0eff,
    A700 : #5100f4,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-venus: (
    50 : #f3eff0,
    100 : #e2d6da,
    200 : #cebbc1,
    300 : #baa0a8,
    400 : #ac8b96,
    500 : #9d7783,
    600 : #956f7b,
    700 : #8b6470,
    800 : #815a66,
    900 : #6f4753,
    A100 : #ffcddc,
    A200 : #ff9ab7,
    A400 : #ff6793,
    A700 : #ff4d81,
    contrast: (
        50 : #9d7783,
        100 : #9d7783,
        200 : #9d7783,
        300 : #9d7783,
        400 : #9d7783,
        500 : #9d7783,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-mulled-wine-theme-background: (
  status-bar: black,
  app-bar:    map_get($md-mulled-wine, 900),
  background: map_get($md-mulled-wine, 900),
  hover:      rgba(#9f7f8e, 0.04), // TODO(kara): check style with Material Design UX
  card:       map_get($md-mulled-wine, 800),
  dialog:     map_get($md-mulled-wine, 800),
  disabled-button: rgba(#9f7f8e, 0.12),
  raised-button: map-get($md-mulled-wine, 800),
  focused-button: $light-focused,
  selected-button: map_get($md-mulled-wine, 900),
  selected-disabled-button: map_get($md-mulled-wine, 800),
  disabled-button-toggle: black,
  unselected-chip: map_get($md-mulled-wine, 700),
  disabled-list-option: black,
  tooltip: map_get($md-venus, 700),
);

$mat-mulled-wine-theme-foreground: (
  base:              map-get($md-venus, 500),
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba(white, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-secondary-text,
  icon:              map-get($md-venus, 300),
  icons:             map-get($md-venus, 300),
  text:              map-get($md-venus, 300),
  slider-min:        map-get($md-venus, 300),
  slider-off:        rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);

$maw-mulled-wine-primary: mat.define-palette($md-venus, A400, A200, A700);
$maw-mulled-wine-accent:  mat.define-palette($md-venus, A400, A200, A700);
$maw-mulled-wine-warn:    mat.define-palette(mat.$red-palette);

// manually create theme otherwise background/foreground is ignored using mat.define-dark-theme()
$maw-mulled-wine-theme: (
    color: (
        primary: $maw-mulled-wine-primary,
        accent: $maw-mulled-wine-accent,
        warn: $maw-mulled-wine-warn,
        is-dark: true,
        foreground: $mat-mulled-wine-theme-foreground,
        background: $mat-mulled-wine-theme-background
    ),
    primary: $maw-mulled-wine-primary,
    accent: $maw-mulled-wine-accent,
    warn: $maw-mulled-wine-warn,
    is-dark: true,
    foreground: $mat-mulled-wine-theme-foreground,
    background: $mat-mulled-wine-theme-background,
    typography: mat.define-typography-config(),
    density: -1
);

.maw-mulled-wine-theme {
    mat-card {
        color: map-get($maw-mulled-wine-accent, 300);
    }
}
