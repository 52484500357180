@use '@angular/material' as mat;
@import '_vars';

$maw-light-primary: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$maw-light-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
$maw-light-warn:    mat.define-palette(mat.$red-palette);

$maw-light-theme: mat.define-light-theme((
    color: (
        primary: $maw-light-primary,
        accent: $maw-light-accent,
        warn: $maw-light-warn
    ),
    typography: mat.define-typography-config(),
    density: -1
));

.maw-light-theme {
    mat-card {
        color: rgba(0, 0, 0, 0.87);
    }
}
