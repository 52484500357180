@use '@angular/material' as mat;
@import '_vars';

$maw-dark-primary: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$maw-dark-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
$maw-dark-warn:    mat.define-palette(mat.$red-palette);

$maw-dark-theme: mat.define-dark-theme((
    color: (
        primary: $maw-dark-primary,
        accent: $maw-dark-accent,
        warn: $maw-dark-warn
    ),
    typography: mat.define-typography-config(),
    density: 0
));

.maw-dark-theme {
    mat-card {
        color: #fff;
    }
}
