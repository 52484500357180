@use '@angular/material' as mat;

@mixin app-component-theme($theme) {
    $accent: map-get($theme, accent);

    a {
        color: mat.get-color-from-palette($accent);
        cursor: pointer;

        &.active {
            background-color: mat.get-color-from-palette($accent, 900);
            color: mat.get-color-from-palette($accent, 200);
        }

        &:hover {
            background-color: mat.get-color-from-palette($accent, 700);
            color: mat.get-color-from-palette($accent, 100);
        }
    }

    .panel-grid {
        background-color: rgba(mat.get-color-from-palette($accent, 700), .2);
    }
}
