@use '@angular/material' as mat;

@mixin app-help-component-theme($theme) {
    $accent: map-get($theme, accent);

    app-help {
        & .version {
            color: mat.get-color-from-palette($accent, 500);
        }
    }
}
