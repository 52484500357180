@use '@angular/material' as mat;

@mixin app-file-listing-component-theme($theme) {
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .download-button,
    .download-button-table {
        color: mat.get-color-from-palette($accent) !important;
    }

    .delete-button,
    .delete-button-table {
        color: mat.get-color-from-palette($warn) !important;
    }
}
